// 申请办卡表单初始化数据 (办卡填写的表单), 深拷贝
const initCardForm = () => {
  // 获取store中的userInfo
  // 字段名称与后端接口字段名称一致 S022
  return {
    // 申领类型（0新申领1换卡2补卡)
    apply_type: '0',
    // 申领类型 1本人 2代未成年人
    person_mode: '1',
    name: '',
    sex: '', // 性别
    cert_type: '居民身份证', // 固定
    cert_no: '',
    sign_org: '', // 签发机关
    cert_start_date: '',
    cert_end_date: '',
    country: '中国', // 固定
    // 民族
    nation: '',
    // 统筹区
    med_whole_name: '',
    med_whole_no: '',
    // 生日
    birthday: '',
    // 行业
    sector: '',
    // 职业
    occupation: '',
    // 联系地址
    contact_addr: '',
    // 单位名称
    emp_name: '',
    // 是否邮寄 1自取2邮寄
    apply_mode: '',
    // 银行名称
    bank_id: '',
    /**
     * (邮寄)
     */
    // 税收居民身份判断
    revenue_type: '',
    // re_name          VARCHAR2	收件人,领卡方式：2邮寄时填写  	Y
    re_name: '',
    // re_address       VARCHAR2	收件人地址,领卡方式：2邮寄时填写  	Y
    re_address_area: '',
    re_address: '',
    // re_mobile_no     VARCHAR2	收件人电话,领卡方式：2邮寄时填写  	Y
    re_mobile_no: '',
    /**
     * 自取
     */
    // brch_id          VARCHAR2	领卡网点编号，S008接口获取,领卡方式：1自取时填写  	Y
    brch_id: '',
    // brch_name        VARCHAR2	领卡网点名称，S008接口获取,领卡方式：1自取时填写  	Y
    brch_name: '',
    brch_address: '',
    // mobile_no        VARCHAR2	联系电话,领卡方式：1自取时填写 	Y
    mobile_no: '',

    // 客户照片编号,16周以上时填写
    person_photo: '',
    // 本人证件照正面编号
    cert_photo1: '',
    // 本人证件照反面编号
    cert_photo2: '',
    // 代理人证件照正面编号
    cert_photo3: '',
    // 代理人证件照反面编号
    cert_photo4: '',
    // 本人户口薄照片编号
    household_photo: '',
    // 代理人户口薄照片编号
    household_photo1: '',

    // 本人办卡
    // 人像
    portrait: {
      url: '',
      id: '',
    },
    // 身份证正面
    idFront: {
      url: '',
      id: '',
    },
    // 身份证反面
    idBack: {
      url: '',
      id: '',
    },
    // 代未成年人办卡
    // 户口本代理人(大人的)
    householdMain: {
      url: '',
      id: '',
    },
    // 户口本办理人页（小孩的）
    householdSelf: {
      url: '',
      id: '',
    },
    // 出生证
    birthCert: {
      url: '',
      id: '',
    },
    // 小孩子的身份证正面
    childIdFront: {
      url: '',
      id: '',
    },
    // 小孩子的身份证反面
    childIdBack: {
      url: '',
      id: '',
    },
  }
}

const state = {
  // 办卡填写的表单
  cardForm: initCardForm(),
}

const mutations = {
  updateCardForm(state, data) {
    // console.log('🚀 ~ updateCardForm ~ payload:', data)
    state.cardForm = { ...state.cardForm, ...data }
  },
  // 重置表单 (由插件处理)
  INIT_CARD_FORM(state) {
    // 重置表单为初始状态
    state.cardForm = initCardForm()
  },
}
const actions = {}
const getters = {}

export default {
  namespaced: true, // 模块化 必要
  state,
  mutations,
  actions,
  getters,
}
