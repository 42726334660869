export default [
  {
    path: '/user',
    component: () => import('@/views/user'),
    children: [
      {
        path: 'authorize',
        component: () => import('@/views/user/authorize'),
        meta: { title: '市民服务专区授权' },
      },
      {
        path: 'my',
        component: () => import('@/views/user/my'),
        meta: { title: '个人中心' },
      },
      {
        path: 'socialCard',
        component: () => import('@/views/user/socialCard'),
        meta: { title: '我的社保卡' },
      },
      {
        path: 'cardUseHistory',
        component: () => import('@/views/user/cardUseHistory'),
        meta: { title: '用卡记录' },
      },
      {
        path: 'tradePwdChange',
        component: () => import('@/views/user/tradePwdChange'),
        meta: { title: '交易密码修改' },
      },
      {
        path: 'tradePwdReset',
        component: () => import('@/views/user/tradePwdReset'),
        meta: { title: '交易密码重置' },
      },
      {
        path: 'faceAuth',
        component: () => import('@/views/user/faceAuth'),
        meta: { title: '人脸验证' },
      },
      {
        path: 'esscSuccess',
        component: () => import('@/views/user/esscSuccess'),
        meta: { title: '申领成功' },
      },
    ],
  },
]
