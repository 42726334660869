/*
 * @Description:
 * @Author: xiexy
 * @Date: 2023-10-25 15:30:36
 * @LastEditTime: 2024-04-12 14:03:32
 * @LastEditors: xiexy
 * @Reference:
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import registerVantComponents from '@/utils/vant.js'
import 'amfe-flexible'
// 主题样式 （必须第一个）
import '@/assets/styles/theme.scss'
// 科友密码键盘样式
import '~static/keyou-encrypt/keyboard.css'
// 全局样式
import '@/assets/styles/common.scss'
// 控制台
import VConsole from 'vconsole'
if (process.env.VUE_APP_ENV_DESC !== '正式环境') new VConsole()

//过滤器
import * as filters from '@/utils/filters.js'
let _filters = {}
Object.keys(filters).forEach((key) => {
  _filters[key] = filters[key]
})

//与APP交互
import bridgejsInc from '@/utils/bridgejsInc'
const _bridge = new bridgejsInc({ appBridgeName: 'YWSMK', appUA: 'YWSMK' })
window._bridge = _bridge

// UA
console.log('UA:', navigator.userAgent)

//全局路由守卫--授权登录拦截
router.beforeEach((to, from, next) => {
  // 设置标题
  if (to.meta.title) document.title = to.meta.title
  next()
})

const app = createApp(App)
registerVantComponents(app)
app.config.productionTip = false
app.config.globalProperties.$filters = _filters
app.config.globalProperties.$bridge = _bridge
app.use(router).use(store).mount('#app')
