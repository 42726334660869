export default [
  {
    path: '/payment',
    component: () => import('@/views/payment'),
    redirect: '/payment/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/payment/home'),
        meta: { title: '收银台' },
      },
    ],
  },
]
