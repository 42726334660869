/*
 * @Description:
 * @Author: xiexy
 * @Date: 2023-10-25 15:30:36
 * @LastEditTime: 2024-03-31 17:51:23
 * @LastEditors: zhouyx
 * @Reference:
 */
import 'vant/lib/index.css'

import {
  Button,
  List,
  Cell,
  CellGroup,
  Icon,
  Swipe,
  SwipeItem,
  NoticeBar,
  Checkbox,
  CheckboxGroup,
  Popup,
  PasswordInput,
  NumberKeyboard,
  Empty,
  ActionSheet,
  Field,
  RadioGroup,
  Radio,
  DropdownMenu,
  DropdownItem,
  Sticky,
  CountDown,
  Form,
  Dialog,
  DatetimePicker,
  Picker,
  Search,
  Tag,
  Grid,
  GridItem,
  Image as VanImage,
  Loading,
  Stepper,
  Tab,
  Tabs,
  Step,
  Steps,
  Collapse,
  CollapseItem,
  ShareSheet,
  PullRefresh,
  Toast,
  overlay,
  Popover,
  AddressEdit,
  Calendar,
  Uploader,
  Divider,
  TreeSelect,
  Area,
} from 'vant'

export default function registerVantComponents(app) {
  app.use(Button)
  app.use(List)
  app.use(Cell)
  app.use(CellGroup)
  app.use(Icon)
  app.use(Swipe)
  app.use(SwipeItem)
  app.use(NoticeBar)
  app.use(Checkbox)
  app.use(CheckboxGroup)
  app.use(Popup)
  app.use(PasswordInput)
  app.use(NumberKeyboard)
  app.use(Empty)
  app.use(ActionSheet)
  app.use(Form)
  app.use(Field)
  app.use(RadioGroup)
  app.use(Radio)
  app.use(DropdownMenu)
  app.use(DropdownItem)
  app.use(Sticky)
  app.use(CountDown)
  app.use(Dialog)
  app.use(DatetimePicker)
  app.use(Picker)
  app.use(Search)
  app.use(Tag)
  app.use(Grid)
  app.use(GridItem)
  app.use(VanImage)
  app.use(Loading)
  app.use(Stepper)
  app.use(Tab)
  app.use(Tabs)
  app.use(Step)
  app.use(Steps)
  app.use(Collapse)
  app.use(CollapseItem)
  app.use(ShareSheet)
  app.use(PullRefresh)
  app.use(overlay)
  app.use(Popover)
  app.use(AddressEdit)
  app.use(Calendar)
  app.use(Uploader)
  app.use(Divider)
  app.use(TreeSelect)
  app.use(Area)
  app.config.globalProperties.$toast = Toast
  app.config.globalProperties.$dialog = Dialog
}
