/*
 * @Description:导出网络配置
 * @Author: xiexy
 * @Date: 2021-06-21 17:01:13
 * @LastEditTime: 2024-04-09 14:42:05
 * @LastEditors: xiexy
 * @Reference:
 */
module.exports = {
  // 默认的接口地址
  baseURL: process.env.VUE_APP_BASE_URL,
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'text/plain; charset=utf-8',
  // 最长请求时间
  requestTimeout: 10000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 接口异常code下单独处理接口
  handleFailCode: [],
  // 路由模式，是否为hash模式
  isHashRouterMode: true,
  // 开发以及部署时的URL hash模式建议使用""代表相对路径或者"/二级目录/" history模式默认使用"/"或者"/二级目录/"
  publicPath: './',
  // 生产环境构建文件的目录名
  outputDir: 'dist',
  // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
  assetsDir: 'static',
  // 开发环境每次保存时是否输出为eslint编译警告
  lintOnSave: true,
  // 进行编译的依赖
  transpileDependencies: ['resize-detector'],
  // 开发环境端口号
  devPort: 9881,
  // 需要自动注入并加载的模块
  providePlugin: {},
  // npm run build时是否自动生成7z压缩包
  build7z: false,
  // npm run build时是否生成gzip
  buildGzip: true,
  // npm run build时是否开启图片压缩，由于国内网路原因image-webpack-loader必须使用cnpm安装，如无法使用cnpm，请配置false
  imageCompression: false,
  // 是否生成离线包
  openOffLine: false,
  // 离线包名字
  offLineName: 'offLineBag',
  // 离线文件存放前端域名
  offLineBaseUrl: '',
  // 开发工具控制台名字显示
  webpackBarName: '义乌市民卡',
  // 浏览器注释显示
  webpackBanner: ' build: h5-ywsmk \n time: ',
  //0国际密 1国密
  enc_algorithm: '1',
}
