export default [
  {
    path: '/express',
    component: () => import('@/views/express'),
    meta: { title: '快递查询' },
    children: [
      {
        path: 'home',
        component: () => import('@/views/express/home'),
      },
      {
        path: 'detail',
        component: () => import('@/views/express/detail'),
      },
      {
        path: 'list',
        component: () => import('@/views/express/list'),
      },
      {
        path: 'relations',
        component: () => import('@/views/express/relations'),
        meta: { title: '选择亲属' },
      },
      {
        path: 'addMember',
        component: () => import('@/views/express/addMember'),
        meta: { title: '添加亲属' },
      },
    ],
  },
]
