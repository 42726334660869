/*
 * @Description:
 * @Author: xiexy
 * @Date: 2024-03-29 15:29:49
 * @LastEditTime: 2024-04-01 16:28:25
 * @LastEditors: xiexy
 * @Reference:
 */
export function name(str = '') {
  if (null != str && str != undefined) {
    if (str.length <= 3) {
      return "*" + str.substring(1, str.length);
    } else if (str.length > 3 && str.length <= 6) {
      return "**" + str.substring(2, str.length);
    } else if (str.length > 6) {
      return str.substring(0, 2) + "****" + str.substring(6, str.length)
    }
  } else {
    return "";
  }
}
export function certNo(val = '') {
  return val.substring(0, 4) + '********' + val.substring(14)
}
export function mobile(val = '') {
  return val.substring(0, 3) + '****' + val.substring(7)
}
export function balance(val = 0, decimal = 2) {
  return (Number(val) / 100).toFixed(decimal)
}
export function cardNo(val = '') {
  return val.substring(0, 4) + ' **** **** *** ' + val.substring(16)
}
export function distance(val = 0) {
  if (Number(val) > 999) return (val / 1000).toFixed(2) + 'km'
  else return val + 'm'
}
