export default [
  {
    path: '/pay',
    component: () => import('@/views/pay'),
    redirect: '/pay/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/pay/home'),
        meta: { title: '充值' },
      },
      {
        path: 'payMent',
        component: () => import('@/views/pay/payMent'),
        meta: { title: '大小钱包充值' },
      },
      {
        path: 'detail',
        component: () => import('@/views/pay/detail'),
        meta: { title: '充值记录' },
      },
      // {
      //   path: 'allBalance',
      //   component: () => import('@/views/pay/allBalance'),
      //   meta: { title: '我的卡账户' },
      // },
    ],
  },
]
