export default [
  {
    path: '/docs',
    component: () => import('@/views/docs'),
    children: [
      {
        path: 'guide',
        component: () => import('@/views/docs/guide'),
        meta: { title: '服务指南' },
      },
      {
        path: 'news',
        component: () => import('@/views/docs/news'),
        meta: { title: '政策服务' },
      },
    ],
  },
]
