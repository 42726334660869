/*
 * @Description: router全局配置
 * @Author: xiexy
 * @Date: 2021-06-21 17:01:13
 * @LastEditTime: 2024-04-12 14:10:00
 * @LastEditors: xiexy
 * @Reference:
 */

import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import { isHashRouterMode, publicPath } from '@/config'

export const routes = [
  {
    path: '/',
    redirect: '/user/authorize',
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/error.vue'),
  },
]
// 自动引入modules下的所有路由
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  routes.push(...files(key).default)
})

export default createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(publicPath)
    : createWebHistory(publicPath),
  routes,
  scrollBehavior: () => ({
    y: 0,
  }),
})
