const state = {
  token: window.localStorage.getItem('token') || '', // app token
  userInfo: JSON.parse(window.localStorage.getItem('userInfo')) || {}, // 用户信息(运管内的)
}

const getters = {}

const mutations = {
  setToken(state, data) {
    state.token = data
    window.localStorage.setItem('token', data)
  },
  setUserInfo(state, data) {
    state.userInfo = data
    window.localStorage.setItem('userInfo', JSON.stringify(data))
  },
}

const actions = {}

export default {
  namespaced: true, // 模块化 必要
  state,
  mutations,
  actions,
  getters,
}
