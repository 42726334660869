export default [
  {
    path: '/wisdomDriving',
    component: () => import('@/views/wisdomDriving'),
    meta: { login: true },
    children: [
      {
        path: 'home',
        component: () => import('@/views/wisdomDriving/home'),
        meta: {
          titlte:""
        },
      },
      {
        path: 'info',
        component: () => import('@/views/wisdomDriving/info'),
        meta: {
          titlte:""
        },
      },
      {
        path: 'bind',
        component: () => import('@/views/wisdomDriving/bind'),
        meta: {
          titlte:""
        },
      },
      {
        path: 'result',
        component: () => import('@/views/wisdomDriving/result'),
        meta: {
          titlte:""
        },
      },
    ],
  },
]
