export default [
  {
    path: '/bankCard',
    component: () => import('@/views/bankCard'),
    meta: { login: true },
    children: [
      {
        path: 'home',
        component: () => import('@/views/bankCard/home'),
        meta: { title: '银行卡' },
      },
      {
        path: 'add',
        component: () => import('@/views/bankCard/add'),
        meta: { title: '添加银行卡' },
      },
      {
        path: 'check',
        component: () => import('@/views/bankCard/check'),
        meta: { title: '验证手机号' },
      },
      {
        path: 'result',
        component: () => import('@/views/bankCard/result'),
        meta: { title: '绑卡结果' },
      },
      {
        path: 'detail',
        component: () => import('@/views/bankCard/detail'),
        meta: { title: '解绑银行卡' },
      },
    ],
  },
]
