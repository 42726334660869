export default [
  {
    path: '/card',
    component: () => import('@/views/card'),
    meta: { login: true },
    children: [
      {
        path: 'cardList',
        component: () => import('@/views/card/cardList'),
        meta: { title: '我的卡账户' },
      },
      {
        path: 'cardDetail',
        component: () => import('@/views/card/cardDetail'),
        meta: { title: '卡详情' },
      },
      {
        path: 'cardInfo',
        component: () => import('@/views/card/cardInfo'),
        meta: { title: '卡信息' },
      },
      {
        path: 'cardBalance',
        component: () => import('@/views/card/cardBalance'),
        meta: { title: '卡余额' },
      },
      {
        path: 'recharge',
        component: () => import('@/views/card/recharge'),
        meta: { title: '充值' },
      },
      {
        path: 'rechargeRecord',
        component: () => import('@/views/card/rechargeRecord'),
        meta: { title: '充值记录' },
      },
      {
        path: 'lossSuccess',
        component: () => import('@/views/card/lossSuccess'),
        meta: { title: '市民卡挂失成功' },
      },
      {
        path: 'handleProcess',
        component: () => import('@/views/card/handleProcess'),
        meta: { title: '办卡进度' },
      },
      {
        path: 'unlossCard',
        component: () => import('@/views/card/unlossCard'),
        meta: { title: '市民卡解挂' },
      },
      {
        path: 'unlossCardSuccess',
        component: () => import('@/views/card/unlossCardSuccess'),
        meta: { title: '市民卡解挂成功' },
      },
    ],
  },
]
