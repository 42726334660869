/*
 * @Description:
 * @Author: xiexy
 * @Date: 2021-09-09 17:59:11
 * @LastEditTime: 2024-04-12 14:12:42
 * @LastEditors: xiexy
 * @Reference:
 */

import dsBridge from 'dsbridge'

function CJJSSDK({ appBridgeName, appUA }) {
  this.appBridgeName = appBridgeName //dsBridge name
  this.appUA = appUA //app userAgent
  /**
   * @description: 获取用户信息
   * @param {Object} clientId
   * @return {*}
   */
  this.getLoginInfo = function ({ onSuccess = () => {}, onFail = () => {} }) {
    const data = dsBridge.call(`${this.appBridgeName}.getLoginInfo`)
    // 转data为对象
    if (data) onSuccess(JSON.parse(data))
    else onFail()
  }
  /**
   * @description: 获取设备信息
   * @param {Object}
   * @return {*}
   */
  this.getDeviceInfo = function ({ onSuccess = () => {}, onFail = () => {} }) {
    const data = dsBridge.call(`${this.appBridgeName}.getDeviceInfo`)
    if (data) onSuccess(JSON.parse(data))
    else onFail()
  }
  /**
   * @description: 扫一扫
   * @param {Object} type "0"普通的扫一扫  "1"门锁绑定
   * @return {*}
   */
  this.scan = function ({
    type = '0',
    onSuccess = () => {},
    onFail = () => {},
  }) {
    dsBridge.call(
      `${this.appBridgeName}.scan`,
      JSON.stringify({ type }),
      (data) => {
        if (data) onSuccess(JSON.parse(data))
        else onFail()
      }
    )
  }
  /**
   * @description:
   * @param {Object} type "0"相册 "1"相机
   * @param {Object} number 数量
   * @return {*}
   */
  this.chooseImage = function ({
    type = '0',
    number = '1',
    onSuccess = () => {},
    onFail = () => {},
  }) {
    dsBridge.call(
      `${this.appBridgeName}.chooseImage`,
      JSON.stringify({ type, number }),
      (data) => {
        if (data) onSuccess(JSON.parse(data))
        else onFail()
      }
    )
  }
  /**
   * @description: copy
   * @param {Object} text
   * @return {*}
   */
  this.copy = function (text) {
    dsBridge.call(`${this.appBridgeName}.copy`, text)
  }

  /**
   * @description: CallPhone
   * @param {String} phone 电话号码
   * @return {*}
   */
  this.CallPhone = function (phone) {
    dsBridge.call(`${this.appBridgeName}.CallPhone`, phone)
  }
}

export default CJJSSDK
