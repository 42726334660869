/*
 * @Author: zhouyx
 * @Date: 2024-04-24 14:32:36
 * @LastEditTime: 2024-04-25 15:33:53
 * @LastEditors: zhouyx
 * @FilePath: \wechat-ywsmk\src\router\relatives.js
 * @程序猿: 佛祖保佑     永不宕机     永无BUG
 */
export default [
  {
    path: "/relatives",
    component: () => import("@/views/relatives/index"),
    meta: {
      title: "绑定列表",
      index: 0,
      requireLogin: true,
      requireVerity: true, //需要实名认证才能进入
    },
    children: [
      {
        path: "home",
        component: () => import("@/views/relatives/home"),
        meta: {
          title: "绑定列表",
          index: 1,
          requireLogin: true,
          requireVerity: true, //需要实名认证才能进入
        },
      },
      {
        path: "list",
        component: () => import("@/views/relatives/list"),
        meta: {
          title: "选择亲属",
          index: 2,
          requireLogin: true,
          requireVerity: true, //需要实名认证才能进入
        },
      },
      {
        path: "relativeList",
        component: () => import("@/views/relatives/relativeList"),
        meta: {
          title: "已添加亲属",
          index: 1,
          requireLogin: true,
          requireVerity: true, //需要实名认证才能进入
        },
      },
      {
        path: "relativeInfo",
        component: () => import("@/views/relatives/relativeInfo"),
        meta: {
          title: "亲属信息",
          index: 2,
          requireLogin: true,
          requireVerity: true, //需要实名认证才能进入
        },
      },
    ],
  },
];
