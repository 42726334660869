// 办卡
export default [
  {
    path: '/applyCard',
    component: () => import('@/views/applyCard'),
    meta: { title: '我要办卡' },
    children: [
      {
        path: 'protocol',
        component: () => import('@/views/applyCard/protocol'),
        meta: { title: '我要办卡' },
      },
      {
        path: 'selectType',
        component: () => import('@/views/applyCard/selectType'),
        meta: { title: '我要办卡' },
      },
      {
        path: 'inputInfo',
        component: () => import('@/views/applyCard/inputInfo'),
        meta: { title: '在线填表' },
      },
      {
        path: 'inputInfoSelf',
        component: () => import('@/views/applyCard/inputInfoSelf'),
        meta: { title: '身份核验' },
      },
      {
        path: 'inputInfoOther',
        component: () => import('@/views/applyCard/inputInfoOther'),
        meta: { title: '身份核验' },
      },
      {
        path: 'inputConfirm',
        component: () => import('@/views/applyCard/inputConfirm'),
        meta: { title: '信息确认' },
      },
    ],
  },
]
