export default [
  {
    path: '/workInjury',
    component: () => import('@/views/workInjury'),
    meta: { title: '工伤报警' },
    children: [
      {
        path: 'home',
        component: () => import('@/views/workInjury/home'),
      },
      {
        path: 'detail',
        component: () => import('@/views/workInjury/detail'),
        meta: { title: '工伤报警详情' },
      },
      {
        path: 'list',
        component: () => import('@/views/workInjury/list'),
        meta: { title: '历史提交记录' },
      },
      {
        path: 'success',
        component: () => import('@/views/workInjury/success'),
        meta: { title: '提交成功' },
      },
    ],
  },
]
