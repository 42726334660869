<!--
 * @Description:
 * @Author: xiexy
 * @Date: 2021-07-02 15:07:55
 * @LastEditTime: 2024-04-15 10:53:27
 * @LastEditors: xiexy
 * @Reference:
-->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="keepAlive">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script setup>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()

  const keepAlive = computed(() => {
    return store.state.keepAlive
  })
</script>
