export default [
  {
    path: '/home',
    component: () => import('@/views/home'),
    redirect: '/home/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/home'),
        meta: { title: '市民服务' },
      },
      {
        path: 'more',
        component: () => import('@/views/home/more'),
        meta: { title: '应用服务' },
      },
    ],
  },
]
