/*
 * @Description:
 * @Author: xiexy
 * @Date: 2023-10-25 15:30:36
 * @LastEditTime: 2024-04-12 15:45:29
 * @LastEditors: xiexy
 * @Reference:
 */

import { createStore } from 'vuex'
import persistedState from 'vuex-persistedstate'

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default createStore({
  modules,
  getters: {},
  state: {
    keepAlive: '', //缓存页面
    deviceInfo: {}, //设备信息
  },
  mutations: {
    setKeepAlive(state, data) {
      state.keepAlive = data
    },
    setDeviceInfo(state, data) {
      state.deviceInfo = data
    },
  },
  actions: {},

  plugins: [persistedState({ storage: window.sessionStorage })],
})
