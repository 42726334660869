export default [
  {
    path: '/net',
    component: () => import('@/views/net'),
    meta: { login: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/net/list'),
        meta: { title: '附近网点' },
      },
      {
        path: 'detail',
        component: () => import('@/views/net/detail'),
        meta: { title: '网点详情' },
      },
    ],
  },
]
